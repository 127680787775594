import * as React from "react";
import Layout from "../components/Layout";
import PartnerCarousel from "../components/carousels/PartnerCarousel";
import ScrollAnimation from "react-animate-on-scroll";

//Images
import manser1 from "../images/manser1.png";
import manser2 from "../images/manser2.png";
import manser3 from "../images/manser3.png";
import manser4 from "../images/manser4.png";
import manser5 from "../images/manser5.png";
import manser6 from "../images/manser6.png";
import check from "../images/check.svg";

//CSS
import "../styles/index.scss";
import "../styles/grids.scss";
import "../styles/particles.scss";
import "../styles/buttons.scss";
import "../styles/elements.scss";
import "../styles/animations.scss";
import ContactForm from "../components/ContactForm";
import { Link } from "gatsby";
import SEO from "../components/seo";

// markup
const ManagedServicesPage = () => {
  const props = {
    seoTitle: "Managed Services",
    seoDescription:
      "Strategix Technology Solutions’ managed IT services build an ecosystem that supports business goals. Optimise network security, scalability and performance.",
  };

  return (
    <Layout>
      <SEO
        title={props.seoTitle}
        description={props.seoDescription}
        // keywords={[]}
        lang="en-gb"
      />
      <main>
        {/* Home Hero */}
        <section>
          <div className="hero" id="managedserv">
            <div className="flex-col-c">
              <div id="long-section-text" className="flex-col-c">
                <ScrollAnimation animateIn="animate__fadeIn" duration={0.75}>
                  <h1 className="fade-intro">Managed Services</h1>
                  <p className="fade-intro">
                    Build an IT ecosystem that supports your business goals.
                  </p>
                </ScrollAnimation>
              </div>
              <div id="long-section-statement" style={{ minHeight: "38vh" }}>
                <div className="w-max">
                  <ScrollAnimation
                    animateIn="animate__fadeInUp"
                    duration={0.75}
                  >
                    <h2>
                      Focus on your core business, while we take care of your
                      information systems. Optimise your network for
                      scalability, security, and performance.
                    </h2>
                  </ScrollAnimation>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Our Range of Managed Services */}

        <section className="half flex-col-c">
          <div style={{ maxWidth: "100vw" }}>
            <div className="w-max">
              <ScrollAnimation animateIn="animate__fadeInUp" duration={0.75}>
                <h1
                  style={{ textAlign: "center", paddingBottom: "1rem" }}
                  className="fade-intro"
                >
                  Our Range of Managed Services
                </h1>
              </ScrollAnimation>
              <div className="man-ser-menu">
                <div className="service-wrap">
                  <Link
                    style={{ color: "inherit", textDecoration: "none" }}
                    to="#on-prem-infrastructure"
                  >
                    <ScrollAnimation
                      animateIn="animate__fadeInUp"
                      duration={0.75}
                    >
                      <div className="service-wrap-image">
                        <img src={manser1} className="image-fit" />
                        <h3>
                          On-Premise <br /> Infrastructure
                        </h3>
                        <p>Deployment within your organisation.</p>
                      </div>
                    </ScrollAnimation>
                  </Link>
                  <Link
                    style={{ color: "inherit", textDecoration: "none" }}
                    to="#data-protection"
                  >
                    <ScrollAnimation
                      animateIn="animate__fadeInUp"
                      duration={0.75}
                      delay={100}
                    >
                      <div className="service-wrap-image">
                        <img src={manser2} className="image-fit" />
                        <h3>
                          Data <br /> Protection
                        </h3>
                        <p>Powered by modern technology.</p>
                      </div>
                    </ScrollAnimation>
                  </Link>
                  <Link
                    style={{ color: "inherit", textDecoration: "none" }}
                    to="#cloud-services"
                  >
                    <ScrollAnimation
                      animateIn="animate__fadeInUp"
                      duration={0.75}
                      delay={200}
                    >
                      <div className="service-wrap-image">
                        <img src={manser3} className="image-fit" />
                        <h3>
                          Cloud <br /> Services
                        </h3>
                        <p>Deliver software-based IT Infrastructure.</p>
                      </div>
                    </ScrollAnimation>
                  </Link>
                  <Link
                    style={{ color: "inherit", textDecoration: "none" }}
                    to="#mail"
                  >
                    <ScrollAnimation
                      animateIn="animate__fadeInUp"
                      duration={0.75}
                      delay={300}
                    >
                      <div className="service-wrap-image">
                        <img src={manser4} className="image-fit" />
                        <h3>
                          Collaboration <br /> and Mail
                        </h3>
                        <p>Trusted Collaboration from Microsoft.</p>
                      </div>
                    </ScrollAnimation>
                  </Link>
                  <Link
                    style={{ color: "inherit", textDecoration: "none" }}
                    to="#digital-workspace"
                  >
                    <ScrollAnimation
                      animateIn="animate__fadeInUp"
                      duration={0.75}
                      delay={400}
                    >
                      <div className="service-wrap-image">
                        <img src={manser5} className="image-fit" />
                        <h3>
                          Digital <br /> Workspace
                        </h3>
                        <p>
                          Solutions that build trust for distributed workforces.
                        </p>
                      </div>
                    </ScrollAnimation>
                  </Link>
                  <Link
                    style={{ color: "inherit", textDecoration: "none" }}
                    to="#security-management"
                  >
                    <ScrollAnimation
                      animateIn="animate__fadeInUp"
                      duration={0.75}
                      delay={500}
                    >
                      <div className="service-wrap-image">
                        <img src={manser6} className="image-fit" />
                        <h3>
                          Security <br /> Management
                        </h3>
                        <p>From desktop to data centre.</p>
                      </div>
                    </ScrollAnimation>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* On-Premise Infrastructure */}
        <a id="on-prem-infrastructure"></a>
        <section className="half flex-col-c pad-inline man-sec" id="man-sec-1">
          <div className="w-max">
            <ScrollAnimation animateIn="animate__fadeInUp" duration={0.75}>
              <h1
                style={{
                  textAlign: "center",
                  paddingBottom: "1rem",
                  paddingTop: "2.5rem",
                }}
                className="fade-in-up"
              >
                On-Premise Infrastructure
              </h1>
            </ScrollAnimation>
            <ScrollAnimation animateIn="animate__fadeInUp" duration={0.75}>
              <div className="service-feature-list">
                <ul>
                  <li>
                    <img src={check} className="check" /> Compute
                  </li>
                  <li>
                    <img src={check} className="check" /> Mobile
                  </li>
                  <li>
                    <img src={check} className="check" /> Datacenter Network
                  </li>
                  <li>
                    <img src={check} className="check" /> Networking
                  </li>
                </ul>
              </div>
            </ScrollAnimation>
            <div
              className="flex-col-c"
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <div className="half-width">
                <ScrollAnimation animateIn="animate__fadeInUp" duration={0.75}>
                  <p>
                    Dedicated on-premise infrastructure and applications provide
                    complete control over hardware, operating systems, database
                    and applications. Private Data Centers are suitable for
                    organisations with many applications or larger more complex
                    workloads.
                    <br />
                    <br />
                    Strategix Technology Solutions introduces flexibility by
                    enhancing customers' on-premise infrastructure or
                    recommending the migration of specific workloads to the
                    cloud. Our extensive experience augments existing
                    engineering teams within an organisation.
                    <br />
                    <br />
                    We support simple and complex networks, server and storage
                    architectures, databases and applications with tight privacy
                    and security measures for sensitive data. Maximise your
                    technology investment with technical and operational
                    resources to build, run and manage your on-premises systems.
                  </p>
                </ScrollAnimation>
                <div
                  style={{
                    paddingBlock: "2rem",
                  }}
                >
                  <Link to="/contact">
                    <button>
                      get in touch <span>&#x279C;</span>
                    </button>
                  </Link>
                </div>
              </div>
              <div className="half-width"></div>
            </div>
          </div>
        </section>

        {/* Data Protection */}
        <a id="data-protection"></a>
        <section className="half flex-col-c pad-inline man-sec" id="man-sec-2">
          <div className="w-max">
            <ScrollAnimation animateIn="animate__fadeInUp" duration={0.75}>
              <h1
                style={{
                  textAlign: "center",
                  paddingBottom: "1rem",
                  paddingTop: "2.5rem",
                }}
                className="fade-in-up"
              >
                Data Protection
              </h1>
            </ScrollAnimation>
            <ScrollAnimation animateIn="animate__fadeInUp" duration={0.75}>
              <div className="service-feature-list">
                <ul>
                  <li>
                    <img src={check} className="check" /> Backup and Recovery
                  </li>
                  <li>
                    <img src={check} className="check" /> Replications
                  </li>
                  <li>
                    <img src={check} className="check" /> Business Continuity
                  </li>
                  <li>
                    <img src={check} className="check" /> Disaster Recovery
                  </li>
                </ul>
              </div>
            </ScrollAnimation>
            <div className="d-flex" style={{ width: "100%" }}>
              <div className="half-width">
                <ScrollAnimation animateIn="animate__fadeInUp" duration={0.75}>
                  <p>
                    IT Departments struggle to get data protection from their
                    backup solutions, often failing audits and successful
                    restores. Ensure your data is stored securely from
                    cyberattacks, ransomware and breaches. Easily access and
                    restore data stored securely off-site.
                    <br />
                    <br />
                    We empower customers with trusted data protection solutions
                    using the latest technologies. Data protection is supported
                    by service level monitoring for mission-critical and
                    infrequent data. Benefit from flexible storage with our
                    pay-per-use and reserved storage options.
                    <br />
                    <br />
                    Strategix offers an easy to use Self-Service or Managed
                    Portal, helping clients define backup strategies for their
                    on-premise or datacenter requirements. Our experts will
                    guide you through any challenges you may face during regular
                    consultations.
                  </p>
                </ScrollAnimation>
                <div
                  style={
                    {
                      // minHeight: '100%',
                      // display: 'flex',
                      // alignItems: 'flex-end',
                    }
                  }
                >
                  <Link to="/data-protection-as-a-service">
                    <button>
                      View all Data Protection services <span>&#x279C;</span>
                    </button>
                  </Link>
                </div>
              </div>
              <div className="half-width"></div>
            </div>
          </div>
        </section>

        {/* Cloud Services */}
        <a id="cloud-services"></a>
        <section className="half flex-col-c pad-inline man-sec" id="man-sec-3">
          <div className="w-max">
            <ScrollAnimation animateIn="animate__fadeInUp" duration={0.75}>
              <h1
                style={{
                  textAlign: "center",
                  paddingBottom: "1rem",
                  paddingTop: "2.5rem",
                }}
                className="fade-in-up"
              >
                Cloud Services
              </h1>
            </ScrollAnimation>
            <ScrollAnimation animateIn="animate__fadeInUp" duration={0.75}>
              <div className="service-feature-list">
                <ul>
                  <li>
                    <img src={check} className="check" /> IAAS
                  </li>
                  <li>
                    <img src={check} className="check" /> PAAS
                  </li>
                  <li>
                    <img src={check} className="check" /> Multi-Cloud Operations
                  </li>
                  <li>
                    <img src={check} className="check" /> BAAS/DRAAS
                  </li>
                </ul>
              </div>
            </ScrollAnimation>

            <div className="d-flex" style={{ width: "100%" }}>
              <div className="half-width">
                <ScrollAnimation animateIn="animate__fadeInUp" duration={0.75}>
                  <p>
                    Our Virtualised Infrastructure services allow you to monitor
                    and manage infrastructure performance and capacity, while
                    ensuring you are able to automatically recover from hardware
                    failures. This enables businesses to greatly reduce OPEX and
                    CAPEX spend and optimise resource allocation.
                    <br />
                    <br />
                    Manage on or off-premises software-defined IT infrastructure
                    through our self-service portal for IT consumers. This
                    ensures automated workload provisioning and lasting
                    resiliency for your business applications.
                    <br />
                    <br />
                    Enable private cloud and expand hybrid cloud, and monitor
                    and manage software-based IT infrastructure with Strategix
                    Technology Solutions' cloud services. Strategix implements
                    Hybrid Cloud Expansion by providing access to cloud-native
                    services and enabling application migration and workload
                    mobility.
                  </p>
                </ScrollAnimation>
                <div
                  style={{
                    paddingBlock: "2rem",
                  }}
                >
                  <Link to="/contact">
                    <button>
                      get in touch <span>&#x279C;</span>
                    </button>
                  </Link>
                </div>
              </div>
              <div className="half-width"></div>
            </div>
          </div>
        </section>

        {/* Collaboration and Mail */}
        <a id="mail"></a>
        <section className="half flex-col-c pad-inline man-sec" id="man-sec-4">
          <div className="w-max">
            <ScrollAnimation animateIn="animate__fadeInUp" duration={0.75}>
              <h1
                style={{
                  textAlign: "center",
                  paddingBottom: "1rem",
                  paddingTop: "2.5rem",
                }}
                className="fade-in-up"
              >
                Collaboration and Mail
              </h1>
            </ScrollAnimation>
            <ScrollAnimation animateIn="animate__fadeInUp" duration={0.75}>
              <div className="service-feature-list">
                <ul>
                  <li>
                    <img src={check} className="check" /> Base Microsoft 365
                  </li>
                  <li>
                    <img src={check} className="check" /> Microsoft 365
                    Optimisation
                  </li>
                  <li>
                    <img src={check} className="check" /> 365 Archive and
                    Protection
                  </li>
                  {/* <li>
                  <img src={check} className="check" /> Networking
                </li> */}
                </ul>
              </div>
            </ScrollAnimation>
            <div className="d-flex" style={{ width: "100%" }}>
              <div className="half-width"></div>
              <div className="half-width">
                <ScrollAnimation animateIn="animate__fadeInUp" duration={0.75}>
                  <p>
                    Businesses face a vast and complicated choice of
                    collaboration tools which makes the decision making process
                    difficult. How do you determine the security necessary to
                    protect your business?
                    <br />
                    <br />
                    We provide trusted collaboration solutions for our
                    customers, utilising Microsoft technologies. As a Cloud
                    Solution Provider Direct, Strategix is able to guide
                    customers with a deep understanding of how to consolidate
                    collaboration software.
                    <br />
                    <br />
                    By providing collaboration tools with key integrated
                    messaging, document management, collaboration and
                    communication channels, Strategix is able to advise the
                    correct product choice, while increasing productivity and
                    security.
                  </p>
                </ScrollAnimation>
                <div
                  style={{
                    paddingBlock: "2rem",
                  }}
                >
                  <Link to="/contact">
                    <button>
                      get in touch <span>&#x279C;</span>
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Digital Workspace */}
        <a id="digital-workspace"></a>
        <section className="half flex-col-c pad-inline man-sec" id="man-sec-5">
          <div className="w-max">
            <ScrollAnimation animateIn="animate__fadeInUp" duration={0.75}>
              <h1
                style={{
                  textAlign: "center",
                  paddingBottom: "1rem",
                  paddingTop: "2.5rem",
                }}
                className="fade-in-up"
              >
                Digital Workspace
              </h1>
            </ScrollAnimation>
            <ScrollAnimation animateIn="animate__fadeInUp" duration={0.75}>
              <div className="service-feature-list">
                <ul>
                  <li>
                    <img src={check} className="check" /> Virtual Desktop
                  </li>
                  <li>
                    <img src={check} className="check" /> Device and Mobile
                    Management
                  </li>
                  <li>
                    <img src={check} className="check" /> Published Applications
                  </li>
                  {/* <li>
                  <img src={check} className="check" /> Networking
                </li> */}
                </ul>
              </div>
            </ScrollAnimation>
            <div className="d-flex" style={{ width: "100%" }}>
              <div className="half-width">
                <ScrollAnimation animateIn="animate__fadeInUp" duration={0.75}>
                  <p>
                    With the ever evolving application landscape, it is
                    challenging to provide adequate security and compliance
                    measures to end-users. This can result in breaches to
                    crucial corporate data.
                    <br />
                    <br />
                    Bring application security and compliancy to any device,
                    anywhere. We provide managed Desktops, Published
                    Applications and compliance solutions directly to the end
                    user.
                    <br />
                    <br />
                    Strategix offers holistic solutions that provide restricted
                    access and compliance to enterprise applications and
                    corporate data.
                  </p>
                </ScrollAnimation>
                <div
                  style={{
                    paddingBlock: "2rem",
                  }}
                >
                  <Link to="/contact">
                    <button>
                      get in touch <span>&#x279C;</span>
                    </button>
                  </Link>
                </div>
              </div>
              <div className="half-width"></div>
            </div>
          </div>
        </section>

        {/* Security Management */}
        <a id="security-management"></a>
        <section className="half flex-col-c pad-inline man-sec" id="man-sec-6">
          <div className="w-max">
            <ScrollAnimation animateIn="animate__fadeInUp" duration={0.75}>
              <h1
                style={{
                  textAlign: "center",
                  paddingBottom: "1rem",
                  paddingTop: "2.5rem",
                }}
                className="fade-in-up"
              >
                Security Management
              </h1>
            </ScrollAnimation>
            <ScrollAnimation animateIn="animate__fadeInUp" duration={0.75}>
              <div className="service-feature-list">
                <ul>
                  <li>
                    <img src={check} className="check" /> Endpoint
                  </li>
                  <li>
                    <img src={check} className="check" /> Micro-Segmentation
                  </li>
                  <li>
                    <img src={check} className="check" /> Network
                  </li>
                  <li>
                    <img src={check} className="check" /> SASE Security
                  </li>
                  <li>
                    <img src={check} className="check" /> Self-Service Password
                    Request
                  </li>
                </ul>
              </div>
            </ScrollAnimation>
            <div className="d-flex" style={{ width: "100%" }}>
              <div className="half-width">
                <ScrollAnimation animateIn="animate__fadeInUp" duration={0.75}>
                  <p>
                    Mitigate breaches and control risk in any environment with
                    Strategix's selection of products. In an ever changing IT
                    security landscape, we help introduce the right products to
                    restore normality and protect your environment.
                    <br />
                    <br />
                    We employ a number of technologies and resources to deliver
                    across the entire security landscape. Our approach
                    emphasises a focus on strong products, from desktop
                    compliance to integrated solutions for datacenters.
                    <br />
                    <br />
                    We cut through the noise and find a solution that best suits
                    your requirements. Security products can be extremely
                    expensive and require human intervention to manage on a day
                    to day. We help you find the right solutions for your
                    organisation's needs and capabilities.
                  </p>
                </ScrollAnimation>
                <div
                  style={{
                    paddingBlock: "2rem",
                  }}
                >
                  <Link to="/contact">
                    <button>
                      get in touch <span>&#x279C;</span>
                    </button>
                  </Link>
                </div>
              </div>
              <div className="half-width"></div>
            </div>
          </div>
        </section>

        {/* Partners */}
        <section style={{ minHeight: "50vh" }}>
          <PartnerCarousel />
        </section>

        {/* Contact */}
        <section className="half">
          <ContactForm />
        </section>
      </main>
    </Layout>
  );
};

export default ManagedServicesPage;
