import { Link } from "gatsby";
import React, { useEffect, useState } from "react";
import ScrollAnimation from "react-animate-on-scroll";
// import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import ReCAPTCHA from "react-google-recaptcha";

// import Axios from 'axios';

import "../styles/contactForm.scss";
import "../styles/grids.scss";

export default function ContactForm() {
  const [align, setAlign] = useState(true);
  const RECAPTCHA_KEY: string = process.env.GATSBY_APP_SITE_RECAPTCHA_KEY!;

  // const url: any = process.env.API_ENDPOINT;

  const [data, setData] = React.useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
    organisation: "",
  });

  // function submit(e: any) {
  //   e.preventDefault();
  //   Axios.post(url, {
  //     firstName: parseInt(data.firstName),
  //     lastName: parseInt(data.lastName),
  //     email: parseInt(data.email),
  //     phone: parseInt(data.phone),
  //     message: parseInt(data.message),
  //     organisation: parseInt(data.organisation),
  //   }).then((res: any) => {
  //     console.log(res.data);
  //   });
  // }

  function handle(e: any) {
    const newData: any = { ...data };
    newData[e.target.id] = e.target.value;
    setData(newData);
    // console.log(newData);
  }

  return (
    <>
      <div className="contact-svg" />
      <div
        className="contact-form"
        style={{
          maxWidth: "100vw",
          alignItems: "center",
        }}
      >
        <div className="w-max">
          <ScrollAnimation animateIn="animate__fadeInUp" duration={1}>
            <h1 style={{ paddingBottom: "2.5rem" }}>Get In Touch</h1>
          </ScrollAnimation>
          <div className="grid-column-3">
            <div id="contact-tagline">
              <p>
                Interested in how Strategix Technology Solutions can accelerate
                your digital transformation journey?
                <br />
                <br />
                Contact us and one of our experts will be in touch.{" "}
              </p>
            </div>
            <div className="contact-info">
              <style dangerouslySetInnerHTML={{ __html: "\n\n" }} />

              <form
                name="contact-form"
                method="post"
                data-netlify="true"
                // netlify-honeypot="bot-field"
                className="form column"
                action="thank-you"
                // onSubmit={(e) => submit(e)}
                data-netlify-recaptcha="true"
              >
                {/* <GoogleReCaptchaProvider
                  reCaptchaKey={RECAPTCHA_KEY}
                  scriptProps={{
                    async: true, // optional, default to false,
                    appendTo: "body", // optional, default to "head", can be "head" or "body",
                    nonce: undefined, // optional, default undefined
                  }}
                > */}
                <input type="hidden" name="bot-field" />
                <input type="hidden" name="form-name" value="contact-form" />
                <div className="row">
                  <div className="column">
                    {/* <label htmlFor="">Name*</label> */}
                    <input
                      onChange={(e) => handle(e)}
                      value={data.firstName}
                      name="name"
                      id="firstName"
                      type="text"
                      tabIndex={1}
                      required
                      className="form-input"
                      placeholder="Name*"
                    />
                  </div>
                  <div className="column">
                    {/* <label htmlFor="">Surname</label> */}
                    <input
                      onChange={(e) => handle(e)}
                      value={data.lastName}
                      name="surname"
                      id="lastName"
                      type="text"
                      tabIndex={2}
                      className="form-input"
                      placeholder="Surname"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="column">
                    {/* <label htmlFor="">Organisation*</label> */}
                    <input
                      onChange={(e) => handle(e)}
                      value={data.organisation}
                      name="organisation"
                      id="organisation"
                      type="text"
                      tabIndex={3}
                      required
                      className="form-input"
                      placeholder="Organisation*"
                    />
                  </div>
                  <div className="column">
                    {/* <label htmlFor="">Phone Number</label> */}
                    <input
                      onChange={(e) => handle(e)}
                      value={data.phone}
                      name="phone"
                      id="phone"
                      type="tel"
                      tabIndex={4}
                      className="form-input"
                      placeholder="Phone Number"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="column">
                    {/* <label htmlFor="">Email *</label> */}
                    <input
                      onChange={(e) => handle(e)}
                      value={data.email}
                      name="email"
                      id="email"
                      type="email"
                      tabIndex={5}
                      required
                      className="form-input"
                      placeholder="Email *"
                    />
                  </div>
                  <div className="column">
                    {/* <label htmlFor="">Type of Enquiry *</label> */}
                    <select
                      name="enquiry"
                      id="enquiry"
                      tabIndex={6}
                      required
                      placeholder="Type of Enquiry *"
                      className={align ? "select-align" : "select-left"}
                      defaultValue="type"
                    >
                      <option value="" disabled hidden>
                        Type of Enquiry * &#9660;
                      </option>
                      <option value="general" onClick={() => setAlign(false)}>
                        General Enquiry
                      </option>
                      <option
                        value="Professional Services"
                        onClick={() => setAlign(false)}
                      >
                        Professional Services
                      </option>
                      <option
                        value="Cloud Services"
                        onClick={() => setAlign(false)}
                      >
                        Cloud Services
                      </option>
                      <option
                        value="Managed Services"
                        onClick={() => setAlign(false)}
                      >
                        Managed Services
                      </option>
                      <option value="Products" onClick={() => setAlign(false)}>
                        Products
                      </option>
                      <option value="Licensing" onClick={() => setAlign(false)}>
                        Licensing
                      </option>
                    </select>
                  </div>
                </div>
                <div className="row">
                  <div className="column">
                    {/* <label htmlFor="">Additional Information</label> */}
                    <textarea
                      onChange={(e) => handle(e)}
                      value={data.message}
                      name="Message"
                      id="message"
                      tabIndex={5}
                      className="form-text-area"
                      // defaultValue={''}
                      placeholder="Write your message here..."
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="column">
                    <label
                      style={{
                        fontSize: "14px",
                        color: "#626366",
                        // marginTop: '-28px',
                        paddingRight: "20px",
                      }}
                    >
                      <input
                        type="checkbox"
                        id="popi"
                        name="popi"
                        value="popi"
                        required
                        style={{
                          fontSize: "20px",
                          marginTop: "24px",
                          marginLeft: "5px",
                          minHeight: "0",
                        }}
                      />
                      <span style={{ fontWeight: "300" }}>
                        {" "}
                        &nbsp; I agree and consent to the{" "}
                        <Link to="/privacy-policy" style={{ color: "#005b85" }}>
                          <strong>Privacy Policy</strong>
                        </Link>
                        , specifically consenting to Strategix Technology
                        Solutions processing my data and communicating with me
                        according to that policy.
                      </span>
                    </label>
                  </div>
                </div>

                <ReCAPTCHA sitekey={RECAPTCHA_KEY} />
                <br />
                <div className="row">
                  <div className="column button">
                    <button
                      name="submit"
                      value="Submit"
                      type="submit"
                      id="contact-submit"
                      data-submit="...Sending"
                    >
                      SUBMIT <span>&#x279C;</span>
                    </button>
                  </div>
                </div>
                {/* </GoogleReCaptchaProvider> */}
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
